<template>
  <div>
    <CDropdown
      inNav
      class="c-header-nav-items"
      placement="bottom-end"
      add-menu-classes="mt-2 pt-0"
    >
      <template #toggler>
        <CHeaderNavLink>
          <font-awesome-icon :icon="['fas', 'user-circle']" size="2x" />
        </CHeaderNavLink>
      </template>
      <h6 class="my-1 text-center" style="padding: 10px">
        {{ store.name + " " + store.lastname }}
      </h6>

      <CDropdownDivider />
      <CDropdownItem @click.native="onClickProfile">
        <font-awesome-icon
          :icon="['far', 'id-card']"
          size="lg"
          class="mr-2"
        />{{ $t("profil") }}
      </CDropdownItem>

      <CDropdownItem @click.native="onClickLogout">
        <font-awesome-icon
          :icon="['fas', 'sign-out-alt']"
          size="lg"
          class="mr-2"
        />{{ $t("exit") }}
      </CDropdownItem>
    </CDropdown>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import store from "../store";
import VueSweetalert2 from "vue-sweetalert2";

Vue.use(VueSweetalert2);

export default {
  name: "DDProfilMenu",
  data() {
    return {
      store: store.state.GetServerData,
      itemsCount: 100,
    };
  },
  methods: {
    onClickProfile(e) {
      this.$router.push("/profile");
    },
    onClickLogout(e) {
      Vue.swal({
        icon: "question",
        title: this.$t("exit"),
        text: this.$t("profil_menu_t1"),
        confirmButtonText: this.$t("yes"),
        showCancelButton: true,
        cancelButtonText: this.$t("not"),
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .get(process.env.VUE_APP_API_URL + "admin/signout")
            .then((response) => {
              store.dispatch("logout");
              // ? remove all storage
              localStorage.clear();
              sessionStorage.clear();
              // ? refresh page
              window.location.reload();
            });
        }
      });
    },
  },
};
</script>

<style lang="scss"></style>
