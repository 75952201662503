import { render, staticRenderFns } from "./ChangePriceTag.vue?vue&type=template&id=8baf459a"
import script from "./ChangePriceTag.vue?vue&type=script&lang=js"
export * from "./ChangePriceTag.vue?vue&type=script&lang=js"
import style0 from "./ChangePriceTag.vue?vue&type=style&index=0&id=8baf459a&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports